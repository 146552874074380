import React from "react"
import logo from "../images/99rises-logotype-WHT-200x58px.png"

const IndexPage = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "#00A651",
      color: "#FFFFFF",
    }}
  >
    <img
      src={logo}
      alt="99rises logo"
      style={{
        width: "150px", // modified width
        height: "auto", // modified height
        maxWidth: "100%", // added maxWidth
        maxHeight: "150px", // added maxHeight
        marginBottom: "10px",
      }}
    />
    <div style={{ fontSize: "18px", marginTop: "10px", lineHeight: "5px" }}>
      <p>
        We have ceased operations. <br />
        Thank you for your support.
      </p>
    </div>
  </div>
)

export default IndexPage
